import React from 'react';
import logo from './assets/logo.svg';
import background from './assets/bg.jpeg';
import ApiService from '../../services/api.service';
import { Loading } from '../../Components/Loading';
import './assets/bootstrap-grid.css';
import './assets/account.css';
import { Link } from 'react-router-dom';

const AccountRegister = () => {
  const apiService = new ApiService();

  // FORM
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [region, setRegion] = React.useState('');

  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [locations, setLocations] = React.useState([]);
  const [message, setMessage] = React.useState('');

  const login = async (e) => {
    e.preventDefault();
    // setLoading(true);

    if (!email) {
      setError('Please provide an email.');
      return;
    }

    const result = await apiService.register(name, email, phone, region);

    if (result) {
      setLoading(false);
      setMessage('Your registration is complete. Please check your inbox.');
    } else {
      setLoading(false);
      setError('Email already in use');
    }
  };

  const init = async () => {
    const result = await apiService.getLocations();
    setLocations(result);
  };

  React.useEffect(() => {
    init();
  }, []);

  if (loading) return <Loading />;

  return (
    <div className="account">
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-2">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
            </div>
            <div className="col-10"></div>
          </div>
        </div>
      </div>
      <div
        className="wrapper"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <form onSubmit={login}>
          <div className="login-form">
            <h3>Register</h3>
            <div className="login-title">
              <div className="login-title-sm font-alt">Welcome to Your</div>
              <div className="login-title-md font-alt">
                Limitless Seas Account
              </div>
            </div>
            <div className="login-content">
              <div className="login-line">
                <input
                  type="text"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <div className="login-line">
                <input
                  type="email"
                  placeholder="E-mail"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div className="login-line">
                <input
                  type="phone"
                  placeholder="Phone"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
              </div>
              {/* <div className="login-line">
                <input
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  //  value={password}
                />
              </div> */}
              <div className="login-line">
                <select onChange={(e) => setRegion(e.target.value)}>
                  <option selected disabled>
                    Select Region
                  </option>
                  {locations.map((x, i) => (
                    <option value={x.name} key={i}>
                      {x.displayname}
                    </option>
                  ))}
                </select>
              </div>
              <div className="login-line" style={{ marginTop: 15 }}>
                <button type="submit" className="button-dark" onClick={login}>
                  Register
                </button>
              </div>

              {message && (
                <div
                  className="message"
                  style={{
                    backgroundColor: 'green',
                    textAlign: 'center',
                    marginTop: 10,
                    fontSize: 12,
                    color: '#ffffff',
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  {message}
                </div>
              )}

              {error && (
                <div
                  className="error"
                  style={{
                    backgroundColor: 'indianred',
                    textAlign: 'center',
                    marginTop: 10,
                    fontSize: 12,
                    color: '#fff',
                  }}
                >
                  {error}
                </div>
              )}

              <div className="login-line" style={{ marginTop: 15 }}>
                <Link to="/account/login">
                  Already have an account? Sign in
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="account-footer">
        Trademark&trade; &amp; Copyright&reg; 2023 Limitless Seas Inc. All rights
        reserved.
      </div>
    </div>
  );
};

export default AccountRegister;
