import React from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '../../Components/Loading';
import ApiService from '../../services/api.service';
import './assets/account.css';
import background from './assets/bg.jpeg';
import './assets/bootstrap-grid.css';
import logo from './assets/logo.svg';

const AccountForgotPassword = () => {
  const apiService = new ApiService();
  const { token } = useParams();

  const [password, setPassword] = React.useState('');
  const [passwordRepeat, setPasswordRepeat] = React.useState('');
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');

  const init = async () => {
    setLoading(true);

    try {
      const user = await apiService.me();

      if (user) {
        window.location.href = '/account';
      } else {
        const info = await apiService.forgot_password_info(token);

        if (info) {
          setEmail(info.email);
        }

        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      await apiService.logout();
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!password || !passwordRepeat) {
      setError('Please provide password');
      setLoading(false);
      return;
    }

    if (password !== passwordRepeat) {
      setError('Passwords are not same.');
      setLoading(false);
      return;
    }

    const result = await apiService.forgot_password_complete(token, password);

    if (result) {
      window.location.href = '/account';
      setLoading(false);
    } else {
      setLoading(false);
      setError('Invalid token');
    }
  };

  if (loading) return <Loading />;

  return (
    <div className="account">
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-2">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
            </div>
            <div className="col-10"></div>
          </div>
        </div>
      </div>
      <div
        className="wrapper"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <form onSubmit={login} style={{ width: 600, maxWidth: '90%' }}>
          <div className="login-form">
            <h3>Account Recovery</h3>
            <div className="login-title">
              <div className="login-title-sm font-alt">{email}</div>
              <div className="login-title-md font-alt">Reset your password</div>
            </div>
            <div className="login-content">
              <div className="login-line">
                <input
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </div>
              <div className="login-line">
                <input
                  type="password"
                  placeholder="Password (Again)"
                  onChange={(e) => setPasswordRepeat(e.target.value)}
                  value={passwordRepeat}
                />
              </div>
              <div className="login-line" style={{ marginTop: 15 }}>
                <button type="submit" className="button-dark" onClick={login}>
                  Submit
                </button>
              </div>
              {error && (
                <div
                  className="error"
                  style={{
                    backgroundColor: 'indianred',
                    textAlign: 'center',
                    marginTop: 10,
                    fontSize: 12,
                    color: '#fff',
                  }}
                >
                  {error}
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="account-footer">
        Trademark™️ &amp; Copyright®️ {new Date().getFullYear()} Limitless Seas
        Inc. All rights reserved.
      </div>
    </div>
  );
};

export default AccountForgotPassword;
