import React from 'react';
import ApiService from '../../services/api.service';
import { Loading } from '../../Components/Loading';
import './assets/bootstrap-grid.css';
import './assets/account.css';
import Nav from './components/Nav';
import { Header } from './components/Header';

const AccountProfile = () => {
  const apiService = new ApiService();

  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState({});
  const [locations, setLocations] = React.useState([]);
  const [updateData, setUpdateData] = React.useState({
    fullname: undefined,
    phone: undefined,
    password: undefined,
    password2: undefined,
  });

  const [showResetPassword, setShowResetPassword] = React.useState(false);
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');

  const init = async () => {
    try {
      const user_data = await apiService.checkUser();

      if (!user_data) {
        window.location.href = '/account/login';
      }

      const me = await apiService.me();
      const locationResult = await apiService.getLocations();
      setLocations(
        locationResult.map((l) => ({ id: l.id, value: l.displayname }))
      );

      setUser(me);

      setUpdateData({
        fullname: me.fullname,
        phone: me.phone,
        location: me.location,
      });

      setLoading(false);
    } catch (e) {
      // setLoading(false);
      await apiService.logout();
      window.location.href = '/';
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  const onResetPassword = (e) => {
    e.preventDefault();
    setShowResetPassword(true);
  };

  const updateField = (name, value) => {
    updateData[name] = value;
    setUpdateData({ ...updateData });
  };

  const update = async (e) => {
    e.preventDefault();

    setError('');

    if (updateData.password !== updateData.password2) {
      setError('Passwords are different.');
      return;
    }

    setLoading(true);

    try {
      const result = await apiService.update(updateData);

      if (result) {
        setLoading(false);
        setSuccess('Your profile has been successfully updated.');
      } else {
        setLoading(false);
        setError('An error occured.');
      }
    } catch (e) {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;

  return (
    <div className="account">
      <Header user={user} />
      <div
        className="wrapper"
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
        }}
      >
        <Nav />
        <div className="account-content">
          <div className="account-content-inner">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-title">Profile</div>
                    <div className="card-content">
                      <div className="row mb-25">
                        <div className="col-md-6">
                          <div className="form-item">
                            <label>Full Name</label>
                            <input
                              type="text"
                              onChange={(e) => {
                                console.log(e.target.value);
                                updateField('fullname', e.target.value);
                              }}
                              value={updateData.fullname}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-item">
                            <label>Email</label>
                            <input type="text" value={user.email} disabled />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-25">
                        <div className="col-md-6">
                          <div className="form-item">
                            <label>Phone</label>
                            <input
                              type="text"
                              onChange={(e) =>
                                updateField('phone', e.target.value)
                              }
                              value={updateData.phone}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-item">
                            <label>Store</label>
                            <select
                              onChange={(e) =>
                                updateField('location', e.target.value)
                              }
                              value={updateData.location}
                            >
                              <option>SELECT</option>
                              {locations.map((l) => (
                                <option key={l.id} value={l.id}>
                                  {l.value}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      {showResetPassword ? (
                        <div className="row mb-25">
                          <div className="col-md-6">
                            <div className="form-item">
                              <label>Password</label>
                              <input
                                type="password"
                                onChange={(e) =>
                                  updateField('password', e.target.value)
                                }
                                value={updateData.password}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-item">
                              <label>Password (again)</label>
                              <input
                                type="password"
                                onChange={(e) =>
                                  updateField('password2', e.target.value)
                                }
                                value={updateData.password2}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row mb-25">
                          <div className="col-md-6">
                            <button
                              className="button-link"
                              onClick={onResetPassword}
                            >
                              Reset Password
                            </button>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        {error && (
                          <div className="col-12 mb-25">
                            <div
                              className="error"
                              style={{
                                backgroundColor: 'indianred',
                                color: '#fff',
                                fontSize: 12,
                              }}
                            >
                              {error}
                            </div>
                          </div>
                        )}

                        {success && (
                          <div className="col-12 mb-25">
                            <div
                              className="error"
                              style={{
                                backgroundColor: 'green',
                                color: '#fff',
                                fontSize: 12,
                              }}
                            >
                              {success}
                            </div>
                          </div>
                        )}

                        <div className="col-12">
                          <button className="button-dark" onClick={update}>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="account-footer">
        Trademark™️ &amp; Copyright®️ 2022 Limitless Seas Inc. All rights
        reserved.
      </div>
    </div>
  );
};

export default AccountProfile;
