import { inject, observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';

import CountUp from 'react-countup';
import { AiOutlineMail } from 'react-icons/ai';
import { ImPhone, ImWhatsapp } from 'react-icons/im';

import BrokerStep from '../Components/BrokerStep';
import CompleteStep from '../Components/CompleteStep';
import DependantPopup from '../Components/DependantPopup';
import DependencyPopup from '../Components/DependencyPopup';
import FactoryFormStep from '../Components/FactoryFormStep';
import GridStep from '../Components/GridStep';
import ImageStep from '../Components/ImageStep';
import EngineStep from '../Components/EngineStep';
import { Loading } from '../Components/Loading';
import PaymentStep from '../Components/PaymentStep';
import PdfStep from '../Components/PdfStep';
import { SpecPopup } from '../Components/SpecPopup';
import { Step } from '../Components/Step';
import SummaryStep from '../Components/SummaryStep';
import { appConfig } from '../config';
import SmoothScrolling from '../Helpers/smootScrolling';
import { Translation } from '../Helpers/Translation';
import ApiService from '../services/api.service';

import 'react-tooltip/dist/react-tooltip.css';
import '../app.css';
import HintPopup from '../Components/HintPopup';
import { toJS } from 'mobx';

@inject('AppStore')
@observer
class App extends React.Component {
  store = this.props.AppStore;
  apiService = new ApiService();

  async componentDidMount() {
    // const user = await this.apiService.checkUser();
    // if (!user) {
    //   window.location.href = '/account/login';
    // }

    const { slug, location, number } = this.props.match.params;

    await this.store.getLocation(location);
    await this.store.loadConfig();

    if (
      !number &&
      this.store.user &&
      this.store.user.user &&
      this.store.user.user.location &&
      this.store.user.user.location.name !== location
    ) {
      window.location =
        '/' + this.store.user.user.location.name + '/build/' + slug;
      return;
    }

    // get path
    await this.store.init(slug, number);

    if (this.store.data.exclusive) {
      console.log(toJS(this.store.data));

      let user = localStorage.getItem('exclusive:user');

      if (!user) {
        this.store.newPage.loggedIn = false;
        this.store.page.loading = false;
      } else {
        this.store.newPage.loggedIn = true;
        this.store.page.loading = false;
      }
    } else {
      this.store.page.loading = false;
    }

    if (this.store.data.background_color) {
      document.documentElement.style.setProperty(
        '--background-color',
        this.store.data.background_color
      );
    }

    if (this.store.data.text_color) {
      document.documentElement.style.setProperty(
        '--text-color',
        this.store.data.text_color
      );
    }

    if (this.store.data.secondary_text_color) {
      document.documentElement.style.setProperty(
        '--secondary-text-color',
        this.store.data.secondary_text_color
      );
    }

    if (this.store.data.color) {
      document.documentElement.style.setProperty(
        '--primary-color',
        this.store.data.color
      );
    }

    if (this.store.data.secondary_color) {
      document.documentElement.style.setProperty(
        '--secondary-color',
        this.store.data.secondary_color
      );
    }
  }

  render() {
    const { page, data } = this.store;

    if (page.loading) return <Loading />;

    if (!this.store.newPage.loggedIn) {
      return (
        <div className="app exclusive">
          <div className="header">
            <div className="location">
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 10 }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.639"
                    height="26"
                    viewBox="0 0 20.639 26"
                  >
                    <g
                      id="Group_13"
                      data-name="Group 13"
                      transform="translate(4931.377 -881.681)"
                    >
                      <path
                        id="Path_63"
                        data-name="Path 63"
                        d="M-4921.058,881.681A10.32,10.32,0,0,0-4931.377,892c0,5.7,10.319,15.681,10.319,15.681s10.319-9.982,10.319-15.681A10.32,10.32,0,0,0-4921.058,881.681Zm0,17.47a7.439,7.439,0,0,1-7.439-7.439,7.438,7.438,0,0,1,7.439-7.437,7.439,7.439,0,0,1,7.439,7.437A7.439,7.439,0,0,1-4921.058,899.151Z"
                        fill="#1f1f20"
                      />
                      <g
                        id="Group_12"
                        data-name="Group 12"
                        transform="translate(-4927.325 885.433)"
                      >
                        <path
                          id="Path_64"
                          data-name="Path 64"
                          d="M-4893.032,913.435l-3.355.031-5.537.051a4.5,4.5,0,0,1,4.454-3.879,4.468,4.468,0,0,1,2.986,1.139h-1.9v1.544l3.02-.02A4.354,4.354,0,0,1-4893.032,913.435Z"
                          transform="translate(4903.69 -907.867)"
                          fill="#1f1f20"
                        />
                        <path
                          id="Path_65"
                          data-name="Path 65"
                          d="M-4896.419,936.387V937.9h1.909a4.47,4.47,0,0,1-2.991,1.142,4.49,4.49,0,0,1-4.462-3.959l5.544.051,3.366.031a4.321,4.321,0,0,1-.346,1.214Z"
                          transform="translate(4903.722 -928.286)"
                          fill="#1f1f20"
                        />
                        <path
                          id="Path_66"
                          data-name="Path 66"
                          d="M-4904.608,900.675a6.286,6.286,0,0,0-6.248,5.678l.773-.009a5.513,5.513,0,0,1,5.475-4.9,5.511,5.511,0,0,1,5.509,5.511,5.508,5.508,0,0,1-5.509,5.509,5.5,5.5,0,0,1-5.482-4.99h-.773a6.284,6.284,0,0,0,6.255,5.761,6.287,6.287,0,0,0,6.279-6.279A6.288,6.288,0,0,0-4904.608,900.675Z"
                          transform="translate(4910.864 -900.675)"
                          fill="#fbd700"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
                <span>
                  {this.store.location && this.store.location.displayname}
                </span>
              </span>
            </div>
            <div className="logo-wrapper">
              <a
                href={`${appConfig.locale.url}/${this.store.location_name}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#000',
                  textDecoration: 'none',
                  fontSize: 12,
                  fontWeight: 'bold',
                }}
              >
                {this.store.configuration && (
                  <img
                    alt="logo"
                    src={
                      appConfig.locale.api + this.store.configuration.logo.url
                    }
                    className="logo"
                  />
                )}
              </a>
            </div>
          </div>
          <div className={`content`}>
            <div>
              <form id="loginform" onSubmit={this.store.fakeLogin}>
                <div className="form">
                  <h2 style={{ textAlign: 'center' }}>Login Form</h2>
                  <div className="form-item">
                    <label>Username</label>
                    <input
                      required
                      type="text"
                      name="username"
                      value={this.store.newPage.username}
                      onChange={(e) =>
                        (this.store.newPage.username = e.target.value)
                      }
                    />
                  </div>
                  <div className="form-item">
                    <label>Password</label>
                    <input
                      required
                      type="password"
                      name="password"
                      value={this.store.newPage.password}
                      onChange={(e) =>
                        (this.store.newPage.password = e.target.value)
                      }
                    />
                  </div>
                  <div style={{ textAlign: 'center', marginTop: 50 }}>
                    <button type="submit" className="button__clean">
                      LOGIN
                    </button>
                  </div>

                  {this.store.newPage.error && (
                    <div
                      style={{
                        color: 'red',
                        textAlign: 'center',
                        marginTop: 15,
                      }}
                    >
                      {this.store.newPage.error}
                    </div>
                  )}

                  {this.store.newPage.success && (
                    <div
                      style={{
                        color: 'green',
                        textAlign: 'center',
                        marginTop: 15,
                      }}
                    >
                      {this.store.newPage.success}
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="app">
        <div className="header">
          <div className="logo-wrapper">
            <a
              href={`${appConfig.locale.url}/${this.store.location_name}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#000',
                textDecoration: 'none',
                fontSize: 12,
                fontWeight: 'bold',
              }}
            >
              {this.store.data.header_logo ? (
                <img
                  src={appConfig.locale.api + this.store.data.header_logo.url}
                  className="logo"
                  alt=""
                />
              ) : (
                <>
                  <img
                    src={
                      appConfig.locale.api + this.store.configuration.logo.url
                    }
                    className="logo"
                    alt=""
                  />
                  <span>
                    {this.store.location && this.store.location.displayname}
                  </span>
                </>
              )}
            </a>
          </div>
          <div
            className="steps"
            style={page.hideSteps ? { display: 'none' } : null}
          >
            {!this.store.page.completed && (
              <React.Fragment>
                {data.steps.map((step, i) => (
                  <Step
                    key={i}
                    order={i + 1}
                    visited={i <= page.stepIndex}
                    selected={i === page.stepIndex}
                    onSelected={() => {
                      this.store.onStepSelected(step, i);
                    }}
                    title={step[this.store.langify('title')]}
                    color={this.store.data.color}
                  />
                ))}
                <Step
                  order={page.summaryStep}
                  visited={page.summaryStep <= page.stepIndex}
                  selected={page.summaryStep <= page.stepIndex}
                  onSelected={() => {
                    this.store.onStepSelected({}, page.summaryStep);
                  }}
                  title={this.store.page.lang === 'en' ? 'SUMMARY' : 'ÖZET'}
                />
              </React.Fragment>
            )}
          </div>

          {this.store.user ? (
            <div className="header-user">
              <Link to="/account">
                <span>
                  <svg
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50"
                  >
                    <path d="M25 1C11.767 1 1 11.767 1 25c0 7.091 3.094 13.472 8 17.869v.017l.348.3c.061.053.128.097.19.149a24.496 24.496 0 0 0 3.189 2.279c.085.051.172.099.257.148.557.324 1.126.629 1.71.908l.018.008a23.838 23.838 0 0 0 3.915 1.456l.075.021c.641.175 1.293.322 1.954.443l.185.033a24.17 24.17 0 0 0 1.939.262c.075.007.15.011.224.017.659.055 1.323.09 1.996.09s1.337-.035 1.996-.09c.075-.006.15-.01.224-.017.655-.06 1.301-.15 1.939-.262l.185-.033a23.451 23.451 0 0 0 1.954-.443l.075-.021a23.838 23.838 0 0 0 3.915-1.456l.018-.008a24.261 24.261 0 0 0 1.71-.908c.086-.05.172-.097.257-.148a24.123 24.123 0 0 0 1.487-.968c.124-.087.248-.174.371-.264.456-.334.9-.683 1.331-1.047.062-.052.129-.096.19-.149l.348-.3v-.017c4.906-4.398 8-10.778 8-17.869C49 11.767 38.233 1 25 1zm0 24c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm3 2c6.065 0 11 4.935 11 11v3.958c-.042.035-.086.067-.128.102-.395.321-.8.626-1.214.918-.092.065-.182.132-.274.195-.447.305-.906.591-1.373.862l-.257.148a21.799 21.799 0 0 1-6.871 2.468l-.171.031a22.27 22.27 0 0 1-1.715.225c-.079.007-.159.012-.239.018-.583.045-1.169.075-1.758.075s-1.175-.03-1.758-.077l-.239-.018a21.789 21.789 0 0 1-1.886-.256 22.013 22.013 0 0 1-5.212-1.626l-.161-.073a21.799 21.799 0 0 1-1.755-.917c-.467-.27-.926-.557-1.373-.862-.093-.063-.183-.13-.274-.195a21.826 21.826 0 0 1-1.214-.918c-.042-.034-.086-.067-.128-.102V38c0-6.065 4.935-11 11-11h6zm13 13.076V38c0-6.271-4.464-11.519-10.38-12.735A9.996 9.996 0 0 0 35 17c0-5.514-4.486-10-10-10s-10 4.486-10 10a9.996 9.996 0 0 0 4.38 8.265C13.464 26.481 9 31.729 9 38v2.076C5.284 36.135 3 30.831 3 25 3 12.869 12.869 3 25 3s22 9.869 22 22c0 5.831-2.284 11.135-6 15.076z" />
                  </svg>
                </span>
                {this.store.user.user.fullname}
              </Link>
            </div>
          ) : (
            <React.Fragment>
              <div
                className="social"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  width: 150,
                }}
              >
                <div>
                  <a
                    href={`https://api.whatsapp.com/send?phone=${
                      this.store.location && this.store.location.phone_whatsapp
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ImWhatsapp color={this.store.data.color} fontSize="20" />
                  </a>
                </div>
                <div>
                  <a
                    href={`mailto:${
                      this.store.location && this.store.location.email
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiOutlineMail
                      color={this.store.data.color}
                      fontSize="24"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href={`tel:${
                      this.store.location && this.store.location.phone
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ImPhone color={this.store.data.color} fontSize="20" />
                  </a>
                </div>
              </div>
              {/* <div
                className="header-user-inner"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingRight: 10,
                  fontSize: 13,
                }}
              >
                <a
                  href={`/account/login`}
                  onClick={this.store.logout}
                  style={{ color: 'var(--text-color)' }}
                >
                  Account
                </a>
              </div> */}
            </React.Fragment>
          )}
        </div>
        <div className="mobile-title">
          {data.logo ? (
            <img
              src={appConfig.locale.api + data.logo.url}
              alt={data.flat_name}
              height={24}
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: data.name }}></span>
          )}
          <div className="pricing">
            <span style={{ fontSize: 12 }}>
              PURCHASE <strong>PRICE:</strong>
            </span>
            <br />
            <CountUp
              className="price-text orbitron"
              separator=","
              decimals={2}
              decimal="."
              prefix={this.store.location.symbol}
              duration={1}
              start={this.store.lastPrice}
              end={this.store.summary.purchase_price}
            />
          </div>
        </div>
        <div className="content">
          {page.completeStep !== page.stepIndex && (
            <React.Fragment>
              {!page.hideSteps &&
                data.steps.map((step, i) => (
                  <div
                    key={i}
                    className={`step-content-inner visible-mobile ${
                      i === page.stepIndex &&
                      'flex animate__animated animate__fadeIn'
                    }`}
                    data-title={step.title}
                  >
                    <div className="title">
                      <span>{step[this.store.langify('title')]}</span>
                    </div>
                    {step.type === 'Engine' && (
                      <EngineStep store={this.store} step={step} data={data} />
                    )}
                    {step.type === 'Image' && (
                      <ImageStep store={this.store} step={step} data={data} />
                    )}
                    {step.type === 'Grid' && (
                      <GridStep store={this.store} step={step} />
                    )}
                  </div>
                ))}
              <div
                id="step-summary"
                className={`step-content-inner visible-mobile ${
                  page.summaryStep === page.stepIndex &&
                  'flex animate__animated animate__fadeIn'
                }`}
                data-title="SUMMARY"
              >
                <div className="title" id="summary">
                  {this.store.page.lang === 'en' ? 'SUMMARY' : 'ÖZET'}
                </div>
                <SummaryStep store={this.store} />
              </div>

              <div
                className={`step-content-inner ${
                  page.pdfStep === page.stepIndex &&
                  'flex animate__animated animate__fadeIn'
                }`}
                data-title="Pdf"
              >
                <div className="title" id="pdf">
                  PDF
                </div>
                <PdfStep store={this.store} />
              </div>

              <div
                className={`step-content-inner ${
                  page.paymentStep === page.stepIndex &&
                  'flex animate__animated animate__fadeIn'
                }`}
                data-title="Payment"
              >
                <div className="title" id="payment">
                  Payment
                </div>
                <PaymentStep store={this.store} />
              </div>

              <div
                className={`step-content-inner ${
                  page.brokerStep === page.stepIndex &&
                  'flex animate__animated animate__fadeIn'
                }`}
                data-title="Broker"
              >
                <div className="title" id="broker">
                  BROKER
                </div>
                <BrokerStep store={this.store} />
              </div>

              <div
                className={`step-content-inner ${
                  page.factoryStep === page.stepIndex &&
                  'flex animate__animated animate__fadeIn'
                }`}
                data-title="Broker"
              >
                <div className="title" id="broker">
                  FACTORY FORM
                </div>
                <FactoryFormStep store={this.store} />
              </div>
            </React.Fragment>
          )}

          {page.completeStep === page.stepIndex && (
            <div
              className={`step-content-inner ${'flex animate__animated animate__fadeIn'}`}
              data-title="Complete"
            >
              <CompleteStep store={this.store} />
            </div>
          )}
        </div>
        <div
          className="footer"
          style={{
            display: page.completeStep === page.stepIndex ? 'none' : 'flex',
          }}
        >
          {!this.store.page.completed && (
            <React.Fragment>
              <div className="price-summary">
                <div className="payment-options">
                  <span>
                    PURCHASE <strong>PRICE:</strong>
                  </span>
                </div>
                <div className="price-display">
                  <CountUp
                    className="price-text orbitron"
                    separator=","
                    decimals={2}
                    decimal="."
                    prefix={this.store.location.symbol}
                    duration={1}
                    start={this.store.lastPrice}
                    end={this.store.summary.purchase_price}
                  />
                </div>
                <div className="buttons_mobile">
                  <div className="modelSelector__cleanbutton">
                    <a
                      onClick={() => {
                        this.store.onStepSelected({}, this.store.page.pdfStep);

                        setTimeout(() => {
                          SmoothScrolling.scrollTo('step-summary');
                        }, 400);
                      }}
                    >
                      SEND PDF
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="estimated"
                style={{
                  flex: 2,
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 12,
                  justifyContent: 'flex-end',
                }}
              >
                <SpecPopup
                  model={this.store.data}
                  image={this.store.getPictureMode('exterior1')}
                />
              </div>
              <div className="delivery">
                <div className="button-wrapper">
                  {page.stepIndex === 0 && (
                    <div
                      className="modelSelector__cleanbutton"
                      style={{ marginRight: 15 }}
                    >
                      <a href={`/${this.store.location_name}`}>
                        <Translation lang={this.store.page.lang} t="prev" />
                      </a>
                    </div>
                  )}

                  {page.stepIndex > 0 &&
                    (page.stepIndex === page.paymentStep ||
                    page.stepIndex === page.brokerStep ||
                    page.stepIndex === page.factoryStep ? (
                      <div
                        className="modelSelector__cleanbutton"
                        style={{ marginRight: 15 }}
                      >
                        <a
                          onClick={() =>
                            this.store.onStepSelected({}, page.summaryStep)
                          }
                        >
                          <Translation lang={this.store.page.lang} t="prev" />
                        </a>
                      </div>
                    ) : (
                      <div
                        className="modelSelector__cleanbutton"
                        style={{ marginRight: 15 }}
                      >
                        <a onClick={this.store.prevStep}>
                          <Translation lang={this.store.page.lang} t="prev" />
                        </a>
                      </div>
                    ))}

                  {page.stepIndex === page.summaryStep &&
                    this.store.user &&
                    this.store.user.user.factory_order && (
                      <div
                        className="modelSelector__cleanbutton"
                        style={{ marginRight: 15 }}
                      >
                        <a
                          onClick={() =>
                            this.store.onStepSelected({}, page.factoryStep)
                          }
                        >
                          FACTORY ORDER
                        </a>
                      </div>
                    )}

                  {page.stepIndex === page.summaryStep &&
                    this.store.user &&
                    this.store.user.user.sales_agreement && (
                      <React.Fragment>
                        <div
                          className="modelSelector__cleanbutton"
                          style={{ marginRight: 15 }}
                        >
                          <a
                            onClick={() =>
                              this.store.onBrokerageStepSelected(
                                {},
                                this.store.page.brokerStep
                              )
                            }
                          >
                            SALES AGREEMENT
                          </a>
                        </div>
                      </React.Fragment>
                    )}

                  {page.stepIndex === page.summaryStep && (
                    <React.Fragment>
                      <div className="modelSelector__cleanbutton">
                        <a
                          onClick={() =>
                            this.store.onStepSelected(
                              {},
                              this.store.page.pdfStep
                            )
                          }
                        >
                          SEND PDF
                        </a>
                      </div>
                      {appConfig.locale.has_payment && (
                        <div
                          className="modelSelector__cleanbutton"
                          style={{ marginLeft: 15 }}
                        >
                          <a
                            onClick={() =>
                              this.store.onStepSelected(
                                {},
                                this.store.page.paymentStep
                              )
                            }
                          >
                            RESERVE NOW
                          </a>
                        </div>
                      )}
                    </React.Fragment>
                  )}

                  {/* {page.stepIndex == page.brokerStep && (
                    <React.Fragment>
                      <div
                        className="modelSelector__cleanbutton"
                        style={{ marginRight: 15 }}
                      >
                        <a
                          onClick={(e) => {
                            this.store.onBrokerPdfSubmit(e);
                          }}
                        >
                          SEND PDF
                        </a>
                      </div>
                      <div className="modelSelector__cleanbutton">
                        <a
                          onClick={(e) => {
                            this.store.onBrokerPdfDownload(e);
                          }}
                        >
                          DOWNLOAD PDF
                        </a>
                      </div>
                    </React.Fragment>
                  )} */}

                  {page.stepIndex === page.pdfStep && (
                    <React.Fragment>
                      <div
                        className="modelSelector__cleanbutton"
                        style={{ marginRight: 15 }}
                      >
                        <a
                          onClick={(e) => {
                            var form = document.getElementById('pdf_form');

                            if (form.reportValidity()) {
                              this.store.onPdfSubmit(e);
                            }
                          }}
                        >
                          SEND PDF
                        </a>
                      </div>

                      {this.store.user &&
                        this.store.user.user.role.type == 'authenticated' && (
                          <div className="modelSelector__cleanbutton">
                            <a
                              onClick={(e) => {
                                this.store.onPdfDownload(e);
                              }}
                            >
                              DOWNLOAD PDF
                            </a>
                          </div>
                        )}
                    </React.Fragment>
                  )}

                  {page.stepIndex < page.summaryStep &&
                    (this.store.checkNext ? (
                      this.store.page.nextEnabled ? (
                        <div className="modelSelector__cleanbutton">
                          <a onClick={this.store.nextStep}>
                            <Translation lang={this.store.page.lang} t="next" />
                          </a>
                        </div>
                      ) : (
                        <div className="modelSelector__cleanbutton disabled">
                          <a>
                            <Translation lang={this.store.page.lang} t="next" />
                          </a>
                        </div>
                      )
                    ) : (
                      <div className="modelSelector__cleanbutton disabled">
                        <a title="You must select at least one option">
                          <Translation lang={this.store.page.lang} t="next" />
                        </a>
                      </div>
                    ))}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>

        <DependencyPopup />
        <DependantPopup />
        <HintPopup />
      </div>
    );
  }
}

export default App;
