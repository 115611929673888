import React from 'react';
import ApiService from '../../services/api.service';
import { Loading } from '../../Components/Loading';
import './assets/bootstrap-grid.css';
import './assets/account.css';
import Nav from './components/Nav';
import { convertCurrency, dateFormat } from '../../helpers';
import { Link } from 'react-router-dom';
import { Header } from './components/Header';

const AccountConfigurations = () => {
  const apiService = new ApiService();

  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [user, setUser] = React.useState({});

  const init = async () => {
    const user_data = await apiService.checkUser();

    if (!user_data) {
      window.location.href = '/account/login';
    }

    setUser(user_data.user);

    const builds = await apiService.getBuilds();
    setData(builds);

    setLoading(false);
  };

  React.useEffect(() => {
    init();
  }, []);

  if (loading) return <Loading />;

  return (
    <div className="account">
      <Header user={user} />
      <div
        className="wrapper"
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
        }}
      >
        <Nav />
        <div className="account-content">
          <div className="account-content-inner">
            <div className="container">
              <div className="row">
                <div className="col">
                  {data.length > 0 ? (
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th align="left">Order Number</th>
                          <th align="left">Model</th>
                          <th align="left">Date</th>
                          <th align="left">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, i) =>
                          item.url ? (
                            <tr key={i}>
                              <td>
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={item.image_url}
                                    style={{ width: 50 }}
                                    alt=""
                                  />
                                </a>
                              </td>
                              <td>
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item.order_number}
                                </a>
                              </td>
                              <td>
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: item.model ? item.model.name : '',
                                    }}
                                  ></span>
                                </a>
                              </td>
                              <td>
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {dateFormat(item.date)}
                                </a>
                              </td>
                              <td>
                                {convertCurrency(item.purchase_price, {
                                  culture: 'en-US',
                                  currency: item.currency,
                                })}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <div style={{ marginTop: 100 }}>
                      <div>You don't have any configuration yet.</div>
                      <div style={{ marginTop: 50 }}>
                        To create new one please click{` `}
                        <Link
                          to={`/${user.location ? user.location.name : 'fl'}`}
                          style={{ color: 'blue' }}
                        >
                          Here
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="account-footer">
        Trademark&trade; &amp; Copyright&reg; 2023 Limitless Seas Inc. All
        rights reserved.
      </div>
    </div>
  );
};

export default AccountConfigurations;
