/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Loading } from '../../Components/Loading';
import ApiService from '../../services/api.service';
import './assets/account.css';
import background from './assets/bg.jpeg';
import './assets/bootstrap-grid.css';
import logo from './assets/logo.svg';

const AccountLogin = () => {
  const params = new URLSearchParams(window.location.search);
  let u = params.get('u');

  const apiService = new ApiService();
  const [email, setEmail] = React.useState(u);
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const init = async () => {
    setLoading(true);
    const user = await apiService.me();

    if (user) {
      window.location.href = '/account';
    } else {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!email || !password) {
      setError('Please provide email / password');
      setLoading(false);
      return;
    }

    const result = await apiService.login(email, password);

    if (result) {
      window.location.href = '/account';
      // setLoading(false);
    } else {
      setLoading(false);
      setError('Invalid username / password');
    }
  };

  const forgotPassword = async (e) => {
    e.preventDefault();
    setError('');

    setShowPassword(true);
  };

  const onForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    const result = await apiService.forgot_password(email);

    if (result) {
      setForgotPasswordSuccess('Please check your mailbox.');
      setLoading(false);
    } else {
      setForgotPasswordSuccess('Please check your mailbox.');

      setLoading(false);
      setError('');
    }
  };

  if (loading) return <Loading />;

  return (
    <div className="account">
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-2">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
            </div>
            <div className="col-10"></div>
          </div>
        </div>
      </div>
      <div
        className="wrapper"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {showPassword ? (
          <form
            onSubmit={onForgotPassword}
            style={{ width: 600, maxWidth: '90%' }}
          >
            <div className="login-form">
              <h3>Forgot Password</h3>
              <div className="login-title" style={{ marginBottom: 10 }}>
                {/* <div className="login-title-sm font-alt">Welcome to</div> */}
                <div className="login-title-sm font-alt">
                  Please enter your email.
                </div>
              </div>
              <div className="login-content">
                <div className="login-line">
                  <input
                    type="email"
                    placeholder="E-mail"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
                <div className="login-line" style={{ marginTop: 15 }}>
                  <button
                    type="submit"
                    className="button-dark"
                    onClick={onForgotPassword}
                  >
                    Reset
                  </button>
                </div>
                {error && (
                  <div
                    className="error"
                    style={{
                      color: 'indianred',
                      textAlign: 'center',
                      marginTop: 10,
                      fontSize: 12,
                    }}
                  >
                    {error}
                  </div>
                )}

                {forgotPasswordSuccess && (
                  <div
                    className="success"
                    style={{
                      color: 'green',
                      textAlign: 'center',
                      marginTop: 10,
                      fontSize: 12,
                    }}
                  >
                    {forgotPasswordSuccess}
                  </div>
                )}
              </div>
            </div>
          </form>
        ) : (
          <form onSubmit={login} style={{ width: 600, maxWidth: '90%' }}>
            <div className="login-form">
              <h3>Login</h3>
              <div className="login-title">
                <div className="login-title-sm font-alt">Welcome to Your</div>
                <div className="login-title-md font-alt">
                  Limitless Seas Account
                </div>
              </div>
              <div className="login-content">
                <div className="login-line">
                  <input
                    type="email"
                    placeholder="E-mail"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
                <div className="login-line">
                  <input
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="login-line" style={{ marginTop: 15 }}>
                  <button type="submit" className="button-dark" onClick={login}>
                    Login
                  </button>
                </div>
                {error && (
                  <div
                    className="error"
                    style={{
                      backgroundColor: 'indianred',
                      textAlign: 'center',
                      marginTop: 10,
                      fontSize: 12,
                      color: '#fff',
                    }}
                  >
                    {error}
                  </div>
                )}

                <div className="login-line" style={{ marginTop: 15 }}>
                  <div style={{ fontSize: 10 }}>
                    If you dont't have an account yet
                  </div>
                  <div>
                    <a href="https://limitlessseas.com/configurator/">
                      Create Account
                    </a>
                  </div>
                </div>
                <div className="login-line" style={{ marginTop: 25 }}>
                  <span style={{ fontSize: 10 }}>Forgot password?</span>{' '}
                  <strong>
                    <a href="#" onClick={forgotPassword}>
                      Click here
                    </a>
                  </strong>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
      <div className="account-footer">
        Trademark&trade; &amp; Copyright&reg; {new Date().getFullYear()} Limitless Seas
        Inc. All rights reserved.
      </div>
    </div>
  );
};

export default AccountLogin;
